import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks'
import { Link } from 'react-router-dom'

import { Menu, MenuItem } from '@mui/material'

import Button from 'components/UI/Button/Button'
import Icon from 'components/UI/Icon'

import { WORKER_PROFILE_PAYMENTS_HISTORY } from 'config/routes'

export default function PayslipMenu({
  worker,
  handleClickViewPayslip,
  showPremiumFeatures,
}) {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'workerPayslipsMenu',
  })

  const closePopup = () => popupState.close()

  return (
    <>
      <Button
        endIcon={<Icon name="chevron-down" />}
        {...bindTrigger(popupState)}
        variant="outlined"
        size="large"
        sx={{ width: { mobile: 1, tablet: 'auto' } }}
      >
        Tus pagos
      </Button>

      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {worker.workers_payslips_access &&
        showPremiumFeatures &&
        worker.payroll_id ? (
          <MenuItem
            onClick={() => {
              handleClickViewPayslip()
              closePopup()
            }}
          >
            Última colilla de pago
          </MenuItem>
        ) : null}

        <MenuItem component={Link} to={WORKER_PROFILE_PAYMENTS_HISTORY()}>
          Historial de nómina
        </MenuItem>
      </Menu>
    </>
  )
}
